import type { ReactNode } from 'react';

import DashboardIcon from '@mui/icons-material/Dashboard';
import ReceiptIcon from '@mui/icons-material/Receipt';
import BusinessIcon from '@mui/icons-material/Business';
import AssignmentIcon from '@mui/icons-material/Assignment';
import { RoleCode } from 'src/utils/types';

export interface MenuItem {
  link?: string;
  icon?: ReactNode;
  badge?: string;
  badgeTooltip?: string;
  items?: MenuItem[];
  name: string;
  roles: RoleCode[];
}

export interface MenuItems {
  items: MenuItem[];
  heading: string;
  roles: RoleCode[];
}

const getMenuItems = (): MenuItems[] => {
  return [
    {
      roles: ['SA', 'AD', 'FIN'],
      heading: 'Inicio',
      items: [
        {
          roles: ['SA', 'AD', 'FIN'],
          name: 'Inicio',
          icon: DashboardIcon,
          link: '/home/admin-dashboard',
        },
      ],
    },
    {
      roles: ['SA', 'FIN'],
      heading: 'Administración',
      items: [
        {
          roles: ['SA', 'FIN'],
          name: 'Cuentas',
          icon: ReceiptIcon,
          link: '/management/bills',
          items: [
            {
              roles: ['SA', 'FIN'],
              name: 'Listar',
              link: '/management/bills/list',
            },
            {
              roles: ['SA', 'FIN'],
              name: 'Crear',
              link: '/management/bills/create',
            },
          ],
        },
        {
          roles: ['SA', 'FIN'],
          name: 'Acreedores',
          icon: BusinessIcon,
          link: '/management/business',
        },
        {
          roles: ['SA', 'FIN'],
          name: 'Tareas',
          icon: AssignmentIcon,
          link: '/management/tasks',
        },
      ],
    },
  ];
};

export default getMenuItems;
