import { useState, useEffect, useCallback } from 'react';
import queryString from 'query-string';
import { useSnackbar } from 'notistack';

import { Business, PaginationMetaDto, CreateBusiness } from '../utils/types';
import { useApi } from './useApi';

type MetaProps = {
  take?: number;
  page?: number;
  order?: 'ASC' | 'DESC';
};

type FilterOptions = {
  justIfHavePendingBills?: boolean;
  billsDueDateFrom?: string;
  billsDueDateTo?: string;
};

type UseBillsProps = {
  metaProps?: MetaProps;
  filterOptions?: FilterOptions;
};

export const useBusiness = ({
  metaProps = {
    take: 20,
    page: 1,
  },
  filterOptions = {},
}: UseBillsProps) => {
  const { take = 10, page = 1, order = 'DESC' } = metaProps || {};

  const [business, setBusiness] = useState<Business[]>([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [meta, setMeta] = useState<PaginationMetaDto>({
    page,
    take,
    itemCount: 0,
    pageCount: 0,
    hasPreviousPage: false,
    hasNextPage: false,
  });
  const [localFilterOptions, setLocalFilterOptions] = useState<FilterOptions>({
    ...filterOptions,
  });
  const [localMetaProps, setLocalMetaProps] = useState<MetaProps>({
    ...metaProps,
  });
  const { get, put, post } = useApi();
  const { enqueueSnackbar } = useSnackbar();

  const fetchBusiness = async () => {
    setLoading(true);
    try {
      const params = {
        order,
        take: localMetaProps.take.toString(),
        page: localMetaProps.page.toString(),
        // Add just the filter options that are not null or undefined or empty string
        ...(Object.keys(localFilterOptions).reduce((acc, key) => {
          if (localFilterOptions[key]) {
            acc[key] = localFilterOptions[key];
          }
          return acc;
        }, {}) as FilterOptions),
      };
      const query = queryString.stringify(params);
      const response = await get<Business[]>('/business?' + query);
      const orderedResponse = response
        .sort((a, b) => {
          return a.id - b.id;
        })
        .reverse();
      setBusiness(orderedResponse);
    } catch (error) {
      setError(true);
    } finally {
      setLoading(false);
    }
  };

  const handleFilterOptionsChange = useCallback(
    (newFilterOptions: FilterOptions, replace = false) => {
      setLocalFilterOptions(
        replace ? newFilterOptions : { ...filterOptions, ...newFilterOptions }
      );
    },
    []
  );

  const handleMetaPropsChange = useCallback(
    (newMetaProps: MetaProps, replace = false) => {
      setLocalMetaProps(
        replace ? newMetaProps : { ...metaProps, ...newMetaProps }
      );
    },
    []
  );

  const createBusiness = async (business: CreateBusiness, cb?: Function) => {
    try {
      await post<Business>(`/business`, business);
      enqueueSnackbar('Empresa creada correctamente', {
        variant: 'success',
      });
      if (cb) cb();
      fetchBusiness();
    } catch (error) {
      console.error('createBusiness -> error', error);
    }
  };

  const handleUpdateBusiness = async (
    id: number,
    bill: Partial<Business>,
    cb?: Function
  ) => {
    try {
      await put<Business>(`/business/${id}`, bill);
      if (cb) cb();
      fetchBusiness();
    } catch (error) {
      console.error('handleUpdateBill -> error', error);
    }
  };

  useEffect(() => {
    fetchBusiness();
  }, [localMetaProps.take, localMetaProps.page]);

  return {
    business,
    loading,
    error,
    handleFilterOptionsChange,
    handleMetaPropsChange,
    handleUpdateBusiness,
    createBusiness,
    fetchBusiness,
    meta,
  };
};

export const useBusinessSecondary = (id: number) => {
  const [business, setBusiness] = useState<Business>();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const { get } = useApi();

  const fetchBusiness = async () => {
    setLoading(true);
    try {
      const response = await get<Business>(`/business/${id}`);
      setBusiness(response);
    } catch (error) {
      setError(true);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchBusiness();
  }, [id]);

  return {
    business,
    loading,
    error,
    fetchBusiness,
  };
};
