import { Box } from '@mui/material';
import HeaderTasks from './Tasks';

function HeaderButtons() {
  return (
    <Box>
      <HeaderTasks />
    </Box>
  );
}

export default HeaderButtons;
