import { Suspense, lazy } from 'react';
import { Navigate } from 'react-router-dom';
import { ConditionalRender } from 'src/components/Guards';

import SuspenseLoader from 'src/components/SuspenseLoader';

const Loader = (Component) => (props) =>
  (
    <Suspense fallback={<SuspenseLoader />}>
      <Component {...props} />
    </Suspense>
  );

// Components
const Bills = Loader(lazy(() => import('src/content/Management/Bills')));
const CreateBill = Loader(
  lazy(() => import('src/content/Management/Bills/create'))
);
const Business = Loader(lazy(() => import('src/content/Management/Business')));
const Tasks = Loader(lazy(() => import('src/content/Management/Tasks/list')));

const homeRoutes = [
  {
    path: '',
    element: <Navigate to="users" replace />,
  },
  {
    path: 'bills',
    children: [
      {
        path: 'list',
        // element: <Bills />,
        element: (
          <ConditionalRender roleCodes={['SA', 'FIN']}>
            <Bills />
          </ConditionalRender>
        ),
      },
      {
        path: 'create',
        // element: <CreateBill />,
        element: (
          <ConditionalRender roleCodes={['SA', 'FIN']}>
            <CreateBill />
          </ConditionalRender>
        ),
      },
    ],
  },
  {
    path: 'business',
    // element: <Business />,
    element: (
      <ConditionalRender roleCodes={['SA', 'FIN']}>
        <Business />
      </ConditionalRender>
    ),
  },
  {
    path: 'tasks',
    children: [
      {
        path: '',
        // element: <Tasks />,
        element: (
          <ConditionalRender roleCodes={['SA', 'FIN']}>
            <Tasks />
          </ConditionalRender>
        ),
      },
    ],
  },
];

export default homeRoutes;
