export interface Task {
  id: number;
  type: TaskType;
  status: TaskStatus;
  filePath: string;
  errorsFilePath: null | string;
  createdAt: string;
  updatedAt: string;
  data: {
    code: string;
    entityId: number;
    participantId: number;
    positionId: number;
  };
}

export enum TaskType {
  UPLOAD_BILLS = 'CARGAR_FACTURAS',
}

export enum TaskStatus {
  PENDING = 'PENDING',
  IN_PROGRESS = 'IN_PROGRESS',
  COMPLETED = 'COMPLETED',
  FAILED = 'FAILED',
}

// Parse TaskType to string translation
export const taskTypeToString = (taskType: TaskType): string => {
  switch (taskType) {
    case TaskType.UPLOAD_BILLS:
      return 'Cargar facturas';
    default:
      return '';
  }
};

// Parse TaskStatus to string translation
export const taskStatusToString = (taskStatus: TaskStatus): string => {
  switch (taskStatus) {
    case TaskStatus.PENDING:
      return 'Pendiente';
    case TaskStatus.IN_PROGRESS:
      return 'En progreso';
    case TaskStatus.COMPLETED:
      return 'Completado';
    case TaskStatus.FAILED:
      return 'Fallido';
    default:
      return '';
  }
};
