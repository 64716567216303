import { OptionLabel } from 'src/common/types/common';
import { Business } from './business';
import { UploadType } from './common';

export enum BillType {
  RECEIVABLE = 'COBRAR',
  PAYABLE = 'PAGAR',
}

export enum BillStatus {
  ENTERED = 'INGRESADO',
  QUEUED = 'EN COLA',
  SETTLED = 'LIQUIDADO',
}

export interface Bill {
  id: number;
  type: BillType;
  documentNumber: string;
  date: string;
  dueDate: string;
  total: number;
  status: BillStatus;
  isAnInvoice: boolean;
  description: string;
  business?: Business;
  enteredAt?: string;
  queuedAt?: string;
  settledAt?: string;
}

export interface CreateBill extends Omit<Bill, 'id'> {
  businessId: number;
}

export const uploadBillTypes: UploadType = {
  uploadOptions: [
    { label: 'Cobrar', value: 'COBRAR' },
    { label: 'Pagar', value: 'PAGAR' },
  ],
};

export const statusArray: OptionLabel[] = [
  // { label: 'Ingresado', value: 'INGRESADO' },
  // { label: 'En cola', value: 'EN COLA' },
  // { label: 'Liquidado', value: 'LIQUIDADO' },
  { label: 'Registrado', value: 'REGISTRADO' },
  { label: 'Liquidado', value: 'LIQUIDADO' },
];
