import { useState, useEffect, useCallback } from 'react';
import queryString from 'query-string';
import { useSnackbar } from 'notistack';

import {
  Bill,
  BillStatus,
  BillType,
  CreateBill,
  Page,
  PaginationMetaDto,
} from '../utils/types';
import { useApi } from './useApi';

type MetaProps = {
  take?: number;
  page?: number;
  order?: 'ASC' | 'DESC';
};

type FilterOptions = {
  id?: number;
  areAboutToExpire?: boolean;
  expirationTimeInDays?: number;
  type?: BillType;
  ignorePagination?: boolean;
  dueDateFrom?: string;
  dueDateTo?: string;
  statuses?: BillStatus[];
  businessId?: number;
};

type UseBillsProps = {
  metaProps?: MetaProps;
  filterOptions?: FilterOptions;
};

export const useBills = ({
  metaProps = {
    take: 20,
    page: 1,
  },
  filterOptions = {},
}: UseBillsProps) => {
  const { take = 10, page = 1, order = 'DESC' } = metaProps || {};

  const [bills, setBills] = useState<Bill[]>([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [meta, setMeta] = useState<PaginationMetaDto>({
    page,
    take,
    itemCount: 0,
    pageCount: 0,
    hasPreviousPage: false,
    hasNextPage: false,
  });
  const [localFilterOptions, setLocalFilterOptions] = useState<FilterOptions>({
    ...filterOptions,
  });
  const [localMetaProps, setLocalMetaProps] = useState<MetaProps>({
    ...metaProps,
  });
  const { get, put, delete: apiDelete } = useApi();
  const { enqueueSnackbar } = useSnackbar();

  const fetchBills = async () => {
    setLoading(true);
    try {
      delete localFilterOptions.id;
      const params = {
        order,
        take: localMetaProps.take.toString(),
        page: localMetaProps.page.toString(),
        // Add just the filter options that are not null or undefined or empty string
        ...(Object.keys(localFilterOptions).reduce((acc, key) => {
          if (localFilterOptions[key]) {
            acc[key] = localFilterOptions[key];
          }
          return acc;
        }, {}) as FilterOptions),
      };
      const query = queryString.stringify(params);
      const response = await get<Page<Bill>>('/bills?' + query);
      if (localFilterOptions.ignorePagination) {
        setBills(response as any);
      } else {
        setBills(response.data);
        setMeta(response.meta);
      }
    } catch (error) {
      setError(true);
    } finally {
      setLoading(false);
    }
  };

  const handleFilterOptionsChange = useCallback(
    (newFilterOptions: FilterOptions, replace = false) => {
      setLocalFilterOptions(
        replace ? newFilterOptions : { ...filterOptions, ...newFilterOptions }
      );
    },
    []
  );

  const handleMetaPropsChange = useCallback(
    (newMetaProps: MetaProps, replace = false) => {
      setLocalMetaProps(
        replace ? newMetaProps : { ...metaProps, ...newMetaProps }
      );
    },
    []
  );

  const handleUpdateBill = async (
    id: number,
    changes: Partial<CreateBill>,
    cb?: Function
  ) => {
    try {
      await put<Bill>(`/bills/${id}`, changes);
      enqueueSnackbar('Factura actualizada', {
        variant: 'success',
      });
      if (cb) cb();
      fetchBills();
    } catch (error) {
      console.error('handleUpdateBill -> error', error);
    }
  };

  const deleteBill = (id: number, cb?: Function) => {
    try {
      apiDelete(`/bills/${id}`);
      enqueueSnackbar('Factura eliminada con éxito', {
        variant: 'success',
      });
      if (cb) cb();
      fetchBills();
    } catch (error) {
      console.error('deleteBill -> error', error);
    }
  };

  useEffect(() => {
    fetchBills();
  }, [localMetaProps.take, localMetaProps.page]);

  return {
    bills,
    loading,
    error,
    handleFilterOptionsChange,
    handleMetaPropsChange,
    handleUpdateBill,
    deleteBill,
    fetchBills,
    meta,
  };
};

export const useBill = (id: number) => {
  const [bill, setBill] = useState<Bill>();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const { get } = useApi();

  const fetchBill = async () => {
    setLoading(true);
    try {
      const response = await get<Bill>(`/bills/${id}`);
      setBill(response);
    } catch (error) {
      setError(true);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchBill();
  }, [id]);

  return {
    bill,
    loading,
    error,
    fetchBill,
  };
};
