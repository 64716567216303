import { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  Divider,
  Avatar,
  List,
  ListItem,
  ListItemText,
  Alert,
  Button,
  Link,
  CircularProgress,
  Autocomplete,
  TextField,
} from '@mui/material';
import { experimentalStyled } from '@mui/material';
import { useDropzone } from 'react-dropzone';
import { CloudUploadTwoTone } from '@mui/icons-material';
import { CloseTwoTone } from '@mui/icons-material';
import { CheckTwoTone } from '@mui/icons-material';
import { Send } from '@mui/icons-material';
import { GetApp } from '@mui/icons-material';
import { Theme, createStyles } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

import { useCustomReducer } from './reducer';
import { uploadBillTypes } from 'src/utils/types';
import { OptionLabel } from 'src/common/types/common';

export enum UploadType {
  RECEIVABLE_ACCOUNTS = 'receivable_accounts',
  ACCOUNTS_FOR_PAY = 'accounts_for_pay',
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    inputRoot: {
      color: 'white !important',
      '& .MuiOutlinedInput-notchedOutline': {
        borderColor: `${theme.colors.secondary.dark} !important`,
      },
      '&:hover .MuiOutlinedInput-notchedOutline': {
        borderColor: `${theme.colors.secondary.main} !important`,
      },
      '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
        borderColor: `${theme.colors.secondary.light} !important`,
      },
      '&::placeholder': {
        textOverflow: 'ellipsis !important',
        color: 'red !important',
      },
    },
  })
);

const BoxUploadWrapper = experimentalStyled(Box)(
  ({ theme }) => `
    border-radius: ${theme.general.borderRadius};
    padding: ${theme.spacing(2)};
    margin-top: ${theme.spacing(2)};
    background: ${theme.colors.alpha.trueWhite[10]};
    border: 1px dashed ${theme.colors.alpha.trueWhite[30]};
    outline: none;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    transition: ${theme.transitions.create(['border', 'background'])};

    &:hover {
      background: ${theme.colors.alpha.trueWhite[5]};
      border-color: ${theme.colors.alpha.trueWhite[70]};
    }
`
);

const UploadBox = experimentalStyled(Box)(
  ({ theme }) => `
    border-radius: ${theme.general.borderRadius};
    padding: ${theme.spacing(2)};
    background: ${theme.colors.alpha.trueWhite[10]};
`
);

const TypographyPrimary = experimentalStyled(Typography)(
  ({ theme }) => `
    color: ${theme.colors.alpha.trueWhite[100]};
  `
);

const TypographySecondary = experimentalStyled(Typography)(
  ({ theme }) => `
    color: ${theme.colors.alpha.trueWhite[70]};
  `
);

const DividerContrast = experimentalStyled(Divider)(
  ({ theme }) => `
    background: ${theme.colors.alpha.trueWhite[10]};
  `
);

const AvatarWrapper = experimentalStyled(Avatar)(
  ({ theme }) => `
    background: ${theme.colors.alpha.trueWhite[10]};
    width: ${theme.spacing(7)};
    height: ${theme.spacing(7)};
`
);

const AvatarSuccess = experimentalStyled(Avatar)(
  ({ theme }) => `
    background: ${theme.colors.success.light};
    width: ${theme.spacing(7)};
    height: ${theme.spacing(7)};
`
);

const AvatarDanger = experimentalStyled(Avatar)(
  ({ theme }) => `
    background: ${theme.colors.error.light};
    width: ${theme.spacing(7)};
    height: ${theme.spacing(7)};
`
);

export const Sidebar = () => {
  const classes = useStyles();
  const [fileComponent, setFileComponent] = useState<JSX.Element>(null);
  const { isUploading, uploadBillsFile, type, handleUpdate } =
    useCustomReducer();

  const {
    acceptedFiles, // Here are the selected files
    isDragActive,
    isDragAccept,
    isDragReject,
    getRootProps,
    getInputProps,
  } = useDropzone({
    accept: {
      file: ['.xlsx'],
    },
    maxFiles: 1,
  });

  // I use this function each time that the file is uploaded to clean the view of the files
  const removeAllFiles = () => {
    setFileComponent(null);
  };

  const sendFile = () => {
    if (acceptedFiles.length > 0) {
      uploadBillsFile(acceptedFiles[0], removeAllFiles);
    }
  };

  useEffect(() => {
    if (acceptedFiles.length > 0) {
      setFileComponent(
        <ListItem disableGutters component="div">
          <ListItemText primary={acceptedFiles[0].name} />
          <b>{acceptedFiles[0].size} bytes</b>
          <DividerContrast />
        </ListItem>
      );
    } else {
      setFileComponent(null);
    }
  }, [acceptedFiles]);

  return (
    <Box>
      <Box sx={{ p: { xs: 2, lg: 4 } }}>
        <Box mb={3} display="flex" alignItems="center">
          <TypographyPrimary variant="h3">Tareas</TypographyPrimary>
        </Box>

        <DividerContrast sx={{ my: 3 }} />

        <UploadBox>
          <TypographyPrimary variant="h4" gutterBottom>
            Carga de Facturas
          </TypographyPrimary>
          <TypographySecondary variant="body1">
            Descarga el formato y súbelo aquí para cargar los datos
          </TypographySecondary>

          <Autocomplete
            classes={classes}
            sx={{ marginTop: 3, marginBottom: 3 }}
            disablePortal
            options={uploadBillTypes.uploadOptions}
            onChange={(_, option: OptionLabel) => {
              handleUpdate('type', option.value ? option.value : 'PAGAR');
            }}
            getOptionLabel={(program: OptionLabel) => program.label}
            renderInput={(params) => (
              <TextField
                fullWidth
                {...params}
                InputLabelProps={{
                  style: { color: 'white' },
                }}
                label={'Tipo de carga'}
                required
              />
            )}
            defaultValue={
              uploadBillTypes.uploadOptions.filter((o) => o.value === type)[0]
            }
          />

          <Box>
            <Button
              sx={{
                my: 1,
              }}
              type="submit"
              variant="contained"
              startIcon={<GetApp fontSize="small" />}
              style={{ width: '100%' }}
            >
              <Link
                style={{ color: 'white', textDecoration: 'none' }}
                href={''}
                download
              >
                Descargar formato
              </Link>
            </Button>
          </Box>

          <BoxUploadWrapper {...getRootProps()}>
            <input {...getInputProps()} />
            {isDragAccept && (
              <>
                <AvatarSuccess variant="rounded">
                  <CheckTwoTone />
                </AvatarSuccess>
                <TypographyPrimary sx={{ mt: 2 }}>
                  Suelta los archivos para empezar a subirlos
                </TypographyPrimary>
              </>
            )}
            {isDragReject && (
              <>
                <AvatarDanger variant="rounded">
                  <CloseTwoTone />
                </AvatarDanger>
                <TypographyPrimary sx={{ mt: 2 }}>
                  No puedes cargar este tipo de archivo
                </TypographyPrimary>
              </>
            )}
            {!isDragActive && (
              <>
                <AvatarWrapper variant="rounded">
                  <CloudUploadTwoTone />
                </AvatarWrapper>
                <TypographyPrimary sx={{ mt: 2 }}>
                  Arrastra y suelta aquí los archivos
                </TypographyPrimary>
              </>
            )}
          </BoxUploadWrapper>
          {fileComponent && (
            <>
              <Alert sx={{ py: 0, mt: 2 }} severity="success">
                {/* Has subido <b>{files.length}</b> archivo! */}
                Listo para subir
              </Alert>
              <DividerContrast sx={{ mt: 2 }} />
              <List disablePadding component="div">
                {fileComponent}
              </List>
            </>
          )}
        </UploadBox>
        {fileComponent && (
          <Box sx={{ textAlign: 'right', mt: 1 }}>
            <Button color="secondary" onClick={removeAllFiles}>
              Cancelar
            </Button>
            <Button
              startIcon={
                isUploading ? (
                  <CircularProgress size="1rem" />
                ) : (
                  <Send fontSize="small" />
                )
              }
              disabled={isUploading}
              onClick={sendFile}
              variant="contained"
            >
              Subir
            </Button>
          </Box>
        )}
        <DividerContrast sx={{ my: 3 }} />
      </Box>
    </Box>
  );
};
