import { Button, Drawer, experimentalStyled, useTheme } from '@mui/material';
import { Assignment } from '@mui/icons-material';
import { useState } from 'react';
import Scrollbars from 'react-custom-scrollbars-2';
import { Sidebar } from 'src/content/Home/Sidebar';
import { ConditionalRender } from 'src/components/Guards';

const DrawerWrapperMobile = experimentalStyled(Drawer)(
  ({ theme }) => `
      width: 350px;
      flex-shrink: 0;
  
    & > .MuiPaper-root {
          width: 370px;
          z-index: 3;
          background: ${theme.colors.gradients.blue3};
          color: ${theme.colors.alpha.white[100]};
    }
  `
);

const HeaderTasks = () => {
  const [rightBar, setRigthBar] = useState(false);
  const handleRigthBar = () => {
    setRigthBar(!rightBar);
  };
  const theme = useTheme();
  const sidebarContent = (
    <Scrollbars autoHide>
      <Sidebar />
    </Scrollbars>
  );

  return (
    <>
      <DrawerWrapperMobile
        anchor={theme.direction === 'rtl' ? 'left' : 'right'}
        open={rightBar}
        onClose={handleRigthBar}
      >
        {sidebarContent}
      </DrawerWrapperMobile>
      <ConditionalRender roleCodes={['SA', 'FIN']}>
        <Button sx={{ color: theme.colors.primary }} onClick={handleRigthBar}>
          <Assignment sx={{ mr: 1 }} />
          Cargas
        </Button>
      </ConditionalRender>
    </>
  );
};

export default HeaderTasks;
