import { Button, Divider } from '@mui/material';
import { useAuthFirebase } from 'src/hooks/useAuth';
import { experimentalStyled } from '@mui/material';

const ImgWrapper = experimentalStyled('img')(
  ({ theme }) => `
    margin-right: ${theme.spacing(1)};
`
);

function LoginFirebaseAuth() {
  const { signInWithGoogle } = useAuthFirebase() as any;

  const handleGoogleClick = async () => {
    try {
      await signInWithGoogle();
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <>
      <Button
        fullWidth
        onClick={handleGoogleClick}
        size="large"
        variant="outlined"
      >
        <ImgWrapper alt="Google" src="/static/images/logo/google.svg" />
        Ingresar con Google
      </Button>
    </>
  );
}

export default LoginFirebaseAuth;
